<template>
  <div class="stops">
    <div class="stops-container">
      <StopSelector
        v-model="pickup"
        type="pickup"
        :stopInformation="pickup"
        :chosenLoad="chosenLoad"
        :salesforceAccounts="salesforceAccounts"
        ref="pickup-stop"
      />
    </div>
    <div class="stops-container">
      <StopSelector
        v-model="delivery"
        type="delivery"
        :stopInformation="delivery"
        :chosenLoad="chosenLoad"
        :salesforceAccounts="salesforceAccounts"
        ref="delivery-stop"
      />
    </div>
  </div>
</template>

<script>
import StopSelector from "./StopSelector.vue";

export default {
  /* eslint-disable prefer-destructuring */
  name: "Stops",
  props: {
    chosenLoad: Object,
    salesforceAccounts: Array,
  },
  components: {
    StopSelector,
  },
  data() {
    return {
      pickup: null,
      delivery: null,
    };
  },
  created() {
    if (this.chosenLoad) {
      this.pickup = this.chosenLoad.stops.find((stop) => stop.is_pickup);
      this.delivery = this.chosenLoad.stops.find((stop) => stop.is_dropoff);
    }
  },
  methods: {
    changeView(view) {
      if (this.$route.name !== view) {
        this.$router.push({ name: view });
      }
    },
    validateFormfields() {
      const pickupValidation = this.$refs["pickup-stop"].validateFormfields();
      const deliveryValidation = this.$refs["delivery-stop"].validateFormfields();
      const pickupDateValidation = this.$refs['pickup-stop'].validateExpectedDate();
      if (pickupValidation && deliveryValidation && pickupDateValidation) {
        return true;
      }
      return false;
    },
  },
};
</script>

<style lang="scss" scoped>
.stops {
  @extend %flex-row-evenly;
  justify-content: space-between;
}
.stops-container {
  width: 48%;
  padding: 10px 0px;
}

///****Responsive styles****\\\\
@media (max-width: 800px) {
  .stops {
    display: flex;
    flex-direction: column;
    margin-top: 5%;
  }
  .stops-container {
  width: 100%;
  padding: 10px 0px;
}
}

</style>
